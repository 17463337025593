// import React, { useEffect, useReducer, useState } from 'react';
// import Swal from 'sweetalert2';
// import { useNavigate } from 'react-router';

// import { BASE_URL } from '../assets/apiRoutes';
// import { useGetAuth } from '../context';
// import { tabsInitState, tabsReducer } from '../utils/reducers/profileReducer';
// import {defaultImage} from '../assets/images/default.jpg'

const Profile = () => {

  // const loggedUser = useGetAuth();

  // const [tabs, dispatch] = useReducer(tabsReducer, tabsInitState);
  // const infoTab = ()=> dispatch({type:"INFORMATION", payload:tabsInitState})

  // const navigate = useNavigate();

  // const [profile, SetProfile] = useState();
  // const [edit, setEdit] = useState();
  // const userLogged = useGetAuth();


  // const getProfile =()=>{
  //   fetch(`${BASE_URL}/users/${userLogged.id}`)
  //     .then(response => response.json())
  //     .then(data => SetProfile(data))
  // }
  // useEffect(() => {
    
  //   getProfile();
  // }, []);
  return (
    <div>Profile</div>
  )
}

export default Profile
