import React, { useState } from "react";
//import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import { loginUser, useDispatchAuth } from "../context";
import Swal from "sweetalert2";
const loginInitialState = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState(loginInitialState);

  const inputChange = (event) => {
    const { name, value } = event.target;
    setLoginForm((prevState) =>({...prevState,[name]: value,}));
  };
  const dispatch = useDispatchAuth();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      loginUser(dispatch, loginForm)
      .then(res=>{
        if(res==="The email & password no existe en la galaxia"){
          Swal.fire({ title: 'Error!', text: 'El email o el password no es correcto', icon: 'error', confirmButtonText: 'Cool' })
          console.log(res)}
          
    else{
        navigate("/home")
    }
      })
        
      setLoginForm(loginInitialState);
      
    } catch (error) {
      Swal.fire({
        title: 'Pruebe de nuevo',
        text: 'Hemos tenido alguna interferencia con el envio del mensaje',
        icon: 'error',
        confirmButtonText: 'Inténtalo de nuevo'
      })
    } 
      
  };

  return (
    <div className="login mx-auto my-5">
      <div className="col-11 col-md-6 mx-auto my-2">
        <div className="card">
          {/* <div className="card-header text-center">Title</div> */}
          <div className="card-body text-center">
            <p>Log in</p>
            <form className="col-md-9 mx-auto" onSubmit={handleLogin}>
              <input
                type="email"
                className="form-control my-2"
                placeholder="email"
                value={loginForm.email}
                onChange={inputChange}
                name="email"
              ></input>
              <input
                type="password"
                className="form-control my-2 "
                placeholder="password"
                name="password"
                value={loginForm.password}
                onChange={inputChange}
                //!pattern="^(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
              ></input>
              <button className="btn btn-dark btn-block">Entrar</button>
              <p className="f-small"> No tienes aún una cuenta  <span> <Link to={"/users/register"}> Regístrate</Link> </span> </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
