import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router';
import { BASE_URL } from "../assets/apiRoutes";

const AventurasBooks = () => {
  const  adventure  = useParams();

  
  let [adventureDetail, SetAdventureDetail] = useState([]);
  
  // const [block, setBlock] = useState(false)
  const [counter, setCounter] = useState(0)

  function addListeners() {
    document.getElementById("paginaLibro").addEventListener("click", desbloquear);
  }
  
  window.onload = function() {
    // init listeners
    addListeners();
    // init content
    // addClass();
  }
  // function addClass() {
  //   var newElem = document.removeChild('paginaLibro');
  //   newElem.style = 'background:red; width:200px; height:200px'; 
  //   var pagina = document.querySelector('h6'); 
  //   pagina.appendChild(newElem);
  // };


   useEffect(() => {
    fetch(`${BASE_URL}/books/${adventure.id}`)
      .then((response) => response.json())
       .then((data) => SetAdventureDetail(data));
   }, [adventure]);
  
  const paginas = Object.entries(adventureDetail).map(entry => {
    const [key, value] = entry
    console.log({ key, value })
    return ( key, value )
    
  })
  const formatedBook = paginas.splice(5)
  console.log(formatedBook)


  function desbloquear() {
    var boton = document.getElementById("miBoton");
    boton.style.backgroundColor = "red";
  }
  // let toggleClassPage = block ? 'active' : 'null';
  
  return (
    <>
      {!adventureDetail ? (
        <p>Cargando...</p>
      ) : (
        <>
            <div className= 'prueba'>
              <h1 className='  text-center'>{adventureDetail.title}</h1>
              <h6 className='text-center container'>{adventureDetail.page_1}</h6>
              <p className='text-center text-light' >Has leído {counter} páginas</p>
              <div className='d-flex flex-wrap justify-content-center my-3 mx-auto'>
                
                  {formatedBook.map((pagina, index) => (
                    <div className=" col-11 col-md-5 col-lg-3 m-3"  >
                      <div className='text-center titlePage'>
                       Página : {index +1}
                        </div>
                      <div key={index} className='paginaStyle p-3 ' >
                      
                {/* <h6 
                          className={`${toggleClassPage} className='paginaStyle p-3 '`}
                >
                {pagina}
                        </h6> */}
                        <h6 
                         id='paginaLibro' className=' p-3 ' onClick={()=> setCounter(counter+1)}
                >
                {pagina}
                        </h6>
                            </div>
                            </div>
                      
          ))} 

              </div>
              </div>
        </>
      )
      } </>
  )
}

export default AventurasBooks