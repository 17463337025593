import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { logout, useDispatchAuth, useGetAuth } from "../../context";

// fojgoierjg
const Header = () => {

  const userLogged = useGetAuth();

  const dispatch = useDispatchAuth();

  const navigate = useNavigate();

  const handleLogout = () => {
    logout(dispatch);
    navigate("/users/login");
  };
  // * <div className="bg-transparent text-white p-2 text-center d-flex flex-column flex-lg-row justify-content-around"></div> */}
  return (
    <>
      <Navbar className="header text-white p-2 text-center d-flex flex-column flex-lg-row justify-content-around" expand="lg">
        <div>
          <Link to="/" className=" text-white text-decoration-none">
            CRYOS Universe
          </Link>
        </div>
        <div>
          <Navbar.Toggle aria-controls="basic-navbar"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="list-unstyled d-flex flex-column flex-lg-row text-center">
              <div className="bg-transparent text-white p-2 text-center d-flex flex-column flex-lg-row justify-content-around">
                {/* {userLogged.id && <Link to="/" className=" text-white text-decoration-none">
                  CRYOS Universe
                </Link>} */}
                {/* Comentar el 'userLogged' para ver los enlaces, hasta que funcione el back correctamente, o quitar
                exclamación antes de "userLogged" */}
                {userLogged.id &&
                  <Link
                    className="mx-3 headerlinks bg-transparent text-decoration-none text-dark btn btn-lg"
                    to="/aventuras"
                  >
                    <span className="headerlinks">Aventuras</span>
                  </Link>}
              {userLogged.id &&
                  <Link
                className="mx-3 headerlinks  text-decoration-none text-dark btn btn-lg"
                to="/galeria"
                  >
                    <span className="headerlinks">Galería</span>

                  </Link>
                }

                  <Link
                className="mx-3 headerlinks  text-decoration-none text-dark btn btn-lg"
                to="/games"
                  >
                    <span className="headerlinks">Juegos</span>

                  </Link>
                {userLogged.id &&
                  <Link className="mx-3 headerlinks bg-transparent text-decoration-none text-dark btn btn-lg"
                    to="/profile">
                    <span className="headerlinks">CRY@SPerfil</span>

                  </Link>}
                {!userLogged.id ? <Link
                  className="mx-3 headerlinks bg-transparent text-decoration-none text-dark btn btn-lg text-lg"
                  to="/users/login"
                >
                 <span className="headerlinks">Log in</span>
                </Link>
                  :
                  <Link
                  className="mx-3 headerlinks bg-transparent text-decoration-none text-dark btn btn-lg text-lg"
                      to="/"
                      onClick={handleLogout}
                >
                 <span className="headerlinks">Log out</span>
                </Link>
                }
                {!userLogged.id && <Link
                  className="mx-3 headerlinks bg-transparent text-decoration-none text-dark btn btn-lg"
                  to="/users/register"
                >
                  <span className="headerlinks">Regístrate</span> 
                  
                </Link>}
                <Link
                className="mx-3 headerlinks bg-transparent text-decoration-none text-dark btn btn-lg"
                to="/contact"
                >
                  <span className="headerlinks">Contacto</span>
              </Link>
                
                </div>
            </ul>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
