import React, { useEffect, useState } from "react";
import { BASE_URL } from "../assets/apiRoutes";
const Galeria = () => {
  let [aventuras, setAventuras] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/books/`)
      .then((response) => response.json())
      .then((data) => setAventuras(data));
  }, []);
console.log(aventuras)
  //const filteredAventuras = aventuras.filter(aventura => aventura.toLowerCase());
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState('')

  const getImg = (image) => {
    setTempImgSrc(image);
    setModel(true)
  }

  return (
    <div className="">
      <div className={model? 'model open' : 'model'}>
        <img src={tempImgSrc} alt="imgGrande" />
        <span className="material-symbols-outlined" onClick={() => setModel(false) }>
        close
        </span>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="fw-bold fs-1 text-center">Fotos tomadas por CRYOS durante sus viajes</h1>

        <div className="gallery mt-5">
          {aventuras.map((aventura) => (
            <div
              key={aventura._id}
              className="pics"
              onClick={()=> getImg(aventura.image)}>
              
                <img
                  className="w-100"
                  src={aventura.image}
                  alt="img-book"
                ></img>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Galeria;
