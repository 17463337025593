import React from 'react'
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const Contacto = () => {

  let navigate = useNavigate();
  //?---------------------------- Envio de formularios mediante emailjs-------------------//
  const sendMail = async (e) => {
    console.log(e, 'formdata');
    try {
      e.preventDefault();
      console.log(e.target.name, 16);
      emailjs.sendForm('service_8pkkw7b', 'template_djnq4wg', e.target, '3-ThZuzxx5rij1COg')
        .then(response => console.log(response))
      Swal.fire({
        title: 'Success!',
        text: 'Mensaje enviado correctamente, nos pondremos en contacto contigo',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
      navigate("/")

    } catch (error) {
      Swal.fire({
        title: 'Pruebe de nuevo',
        text: 'Hemos tenido alguna interferencia con el envio del mensaje',
        icon: 'error',
        confirmButtonText: 'Inténtalo de nuevo'
      })
      console.log(error)
      navigate("/contact");
    }
  }
  //const sendAll = (sendMail, handleSubmit)
  
   //?---------------------------- Envio de formularios mediante emailjs-------------------//
  return (
    <div className='d-flex flex-lg-row flex-column mb-5'>

      <div className='col-4 p-auto mx-auto my-5'>
        <img className='w-100 rounded-4' src='https://res.cloudinary.com/manuelcodex/image/upload/v1666891519/CRY%40S/20825780_slht8b.jpg' alt='contactImage'></img>
      </div>
        <form className='col-10 col-lg-7 p-auto mx-auto my-lg-auto my-4' id="formCryos" onSubmit={sendMail}>
        <div className='d-flex flex-column justify-content-center '>
            <h1 className='fontHLIB d-xl-inline text-center'> <span className='d-none d-xl-inline'>Formulario de</span>  contacto</h1>
        </div>
<div className='d-flex '>
          <input type="text" className='form-control fontHLIB mb-2 ' name='InputName' id="InputName" placeholder="Nombre"/>
          <select className='form-select mb-2 mx-2 bg-dark text-light' defaultValue={'DEFAULT'} name="inputOption">
          <option value="DEFAULT" disabled>¿Qué necesitas?</option>
          <option value="cuenta" name="cuenta">Problemas con la cuenta</option>
          <option value="tematica" name="tematica">Proposición para temática de cuentos</option>
          <option value="opinion" name="opinion">Opinión personal sobre CRY@OS</option>
          </select>
        </div>

          <input type="email" className='form-control fontHLIB mb-2 w-100 mx-auto' name='InputEmail' id="InputEmail" placeholder="email@example.com"/>

          <textarea type="text" rows={4} className='form-control fontHLIB' name='InputMessage' id="InputMessage" placeholder="Mensaje"/>

        <p className=' text-center'> <small> * Si no recibe un mensaje de confirmación a su correo en las próximas 24 horas, por favor, envíe otro email</small></p>
        <div className=' d-flex flex-direction justify-content-center p-1 '>
        <button className='btn btn-dark text-light w-100 w-lg-50 fontHLIB '> <span className='fontHLIB'>Enviar</span></button>
        </div>
        </form>
    </div>
  )
}

export default Contacto