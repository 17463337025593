import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../assets/apiRoutes";


const Aventuras = () => {
  let [aventuras, setAventuras] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/books/`)
      .then((response) => response.json())
      .then((data) => setAventuras(data));
  }, []);
console.log(aventuras)
  //const filteredAventuras = aventuras.filter(aventura => aventura.toLowerCase());

  return (
    <div className="container">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="fw-bold fs-1 text-center">Elige tu próxima aventura</h1>

        <div className="d-flex flex-wrap my-3 mx-auto">
          {aventuras.map((aventura) => (
            <div
              key={aventura._id}
              className="rounded-4 hBorder col-12 col-lg-4 mb-2 "
            >
                <Link to={`/aventuras/${aventura._id}`} className="col-12 d-flex flex-column align-items-center text-decoration-none">
                <img
                  className="w-50 rounded-5 mt-2"
                  src={aventura.image}
                  alt="img-book"
                ></img>
                <div className=" col-12 ">
                  <div className="fw-bold fs-5 text-center text-dark">
                    {aventura.title}
                  </div>
                  <div className="fw-bold fs-6 text-center text-dark ">
                    {aventura.description}
                  </div>
                </div>
                </Link>
              
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Aventuras;
