import React from 'react'

const Home = () => {

    return (
        <>
            {/* <div className=' bg-black mb-2 d-flex justify-content-center'>
                <img className='w-25 align-self-center ' src='https://res.cloudinary.com/manuelcodex/image/upload/v1666891519/CRY%40S/20825780_slht8b.jpg' alt='home1'></img>
                </div> */}
            <div className='container'>
                {/* <section>
                <img className='w-50 h-100 rounded-4 d-none d-md-block' src='https://res.cloudinary.com/manuelcodex/image/upload/v1666891519/CRY%40S/20825780_slht8b.jpg' alt='home1'></img>

                </section> */}
                <div className='d-flex flex-column flex-md-row justify-content-center align-items-center my-3'>
                    <div className='w-100'>
                        <img className='w-100 rounded-4 d-none d-md-block' src='https://res.cloudinary.com/manuelcodex/image/upload/v1666891519/CRY%40S/20825780_slht8b.jpg' alt='home1'></img>
                    </div>
                    <div className='m-3'>
                        <p className='fontPar'>Os presentamos a <span className='fw-bold'>CRY@S</span>,  nuestro aventurero espacial. CRY@S tiene 10 añitos, y desde los 5 vive aventuras en el espacio, conociendo y descrubriendo nuevos mundos. A CRY@S le gustaría que viviésemos junto a él todas sus aventuras y le ayudemos a disfrutarlas al máximo.  Recorre junto a CRY@S los planetas que visite, a través de la lectura.</p>
                        <p className='fontPar'>Os presentamos a <span className='fw-bold'>CRY@S</span>,  nuestro aventurero espacial. CRY@S tiene 10 añitos, y desde los 5 vive aventuras en el espacio, conociendo y descrubriendo nuevos mundos. CRY@S ha pensado compartir todas sus aventuras con nosotros para que podamos aprender acerca del universo que nos rodea.  Recorre junto a CRY@S los planetas que visite, a través de la lectura.</p>

                    </div>
                </div>
                <div className='d-flex flex-column flex-md-row-reverse justify-content-center align-items-center my-3'>
                    <div className='w-100'>
                        <img className='w-100 rounded-4 d-none d-md-block' src='https://res.cloudinary.com/manuelcodex/image/upload/v1666891520/CRY%40S/3800_2_06_q5gcsl.jpg' alt='home1'></img>
                    </div>
                    <div className='m-3'>
                        <p className='fontPar'> CRY@S  siempre va acompañado por sus sondas espaciales <span className='fw-bold'>Hugin</span>  y <span className='fw-bold'>Munin</span> que nos proporcionarán algunos datos del planeta que vamos a visitar. La nave que utiliza para viajar de un planeta a otro también tiene nombre y es <span className='fw-bold'>Yggdrasil</span> , aún no sabemos porque se llama así pero seguro que encontramos su significado en alguno de nuestos viajes.
                        
                {/* { !userLogged.id &&
                  <Link
                    className="mx-3 headerlinks bg-transparent text-decoration-none text-dark btn btn-lg"
                    to="/aventuras"
                  >
                    <span className="headerlinks">Aventuras</span>
                                </Link>} */}
                        </p>
                        <p className='fontPar'> CRY@S  siempre va acompañado por sus sondas espaciales <span className='fw-bold'>Hugin</span>  y <span className='fw-bold'>Munin</span> que nos proporcionarán algunos datos del planeta que vamos a visitar. La nave que utiliza para viajar de un planeta a otro también tiene nombre y es <span className='fw-bold'>Yggdrasil</span> , aún no sabemos porque se llama así pero seguro que encontramos su significado en alguno de nuestos viajes. </p>

                    </div>
                </div>
                {/* <div>
                        <img className='w-100 mb-2' src='https://res.cloudinary.com/manuelcodex/image/upload/v1668254301/CRY%40S/helloWorldBig_lp8pd6.jpg' alt='home1'></img>
                    </div> */}
            </div>
            
        </>
    )
}

export default Home