import React from "react";

const Juegos = () => {
  return (
    <div className="container">
      <h1 className="text-center">Juegos</h1>
      <div className="d-flex flex-wrap flex-md-row flex-column justify-content-center align-content-center my-3 mx-5 mx-md-auto">
      <div className=" col-12 col-md-4 mb-2 ">
        <a
          href="https://arkanoid-weld.vercel.app/"
          rel="noreferrer"
          target="_blank"
        >
          <img
width="90%" 
height="200"
            src="https://res.cloudinary.com/manuelcodex/image/upload/v1675097686/CRY%40S/arkanoid_gckaho.png"
            alt="arkanoid"
          />
        </a>
        </div>
        <div className=" col-12 col-md-4 mb-2 ">
        <a
          href="https://arkanoid-weld.vercel.app/"
          rel="noreferrer"
          target="_blank"
        >
          <img
width="90%" 
height="200"
            src="https://res.cloudinary.com/manuelcodex/image/upload/v1675097686/CRY%40S/arkanoid_gckaho.png"
            alt="arkanoid"
          />
        </a>
        </div>
        <div className=" col-12 col-md-4 mb-2 ">
        <a
          href="https://arkanoid-weld.vercel.app/"
          rel="noreferrer"
          target="_blank"
        >
          <img
width="90%" 
height="200"
            src="https://res.cloudinary.com/manuelcodex/image/upload/v1675097686/CRY%40S/arkanoid_gckaho.png"
            alt="arkanoid"
          />
        </a>
        </div>
        <div className=" col-12 col-md-4 mb-2 ">
        <a
          href="https://arkanoid-weld.vercel.app/"
          rel="noreferrer"
          target="_blank"
        >
          <img
width="90%" 
height="200"
            src="https://res.cloudinary.com/manuelcodex/image/upload/v1675097686/CRY%40S/arkanoid_gckaho.png"
            alt="arkanoid"
          />
        </a>
        </div>
        <div className=" col-12 col-md-4 mb-2 ">
        <a
          href="https://arkanoid-weld.vercel.app/"
          rel="noreferrer"
          target="_blank"
        >
            <img
width="90%" 
height="200"
            src="https://res.cloudinary.com/manuelcodex/image/upload/v1675097686/CRY%40S/arkanoid_gckaho.png"
            alt="arkanoid"
          />
        </a>
        </div>
        <div className=" col-12 col-md-4 mb-2 ">
        <a
          href="https://arkanoid-weld.vercel.app/"
          rel="noreferrer"
          target="_blank"
        >
          <img
width="90%" 
height="200"
            src="https://res.cloudinary.com/manuelcodex/image/upload/v1675097686/CRY%40S/arkanoid_gckaho.png"
            alt="arkanoid"
          />
        </a>
        </div>
      </div>
    </div>
  );
};

export default Juegos;
