import Register from "../pages/Register";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Aventuras from "../pages/Aventuras";
import AventurasBooks from "../pages/AventurasBooks";
import Galeria from "../pages/Galeria";
import Contacto from "../pages/Contacto";
import Juegos from "../pages/Juegos";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/users/login",
    element: <Login />,
    
  },
  // !{
  //  ! path: "/users/:id",
  //   !element: <Login />,
  //   !},
  {

        path: "/users/register",
        element: <Register />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    
    path: "/aventuras",
    element: <Aventuras />,
  },
  {
    path: "/aventuras/:id",
    element: <AventurasBooks />,
  },
  {
    path: "/games",
    element: <Juegos />,
  },
  {
    path: "/galeria",
    element: <Galeria />,
  },
  {
    path: "/contact",
    element: <Contacto />,
  },
    
]

export default routes