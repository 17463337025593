import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom"
import { registerUser, useDispatchAuth } from '../context';
import Swal from "sweetalert2";


const loginInitialState = {
  email: "",
  password: "",
  name: "",
  username: "",
};

const passwordRegexp = new RegExp(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,}$/);

const Register = (props) => {

  const [passwordField, setPasswordField] = React.useState({
    value: "",
    hasError: false,
  });



  const navigate = useNavigate();
  const dispatch = useDispatchAuth();

  const [registerForm, setRegisterForm] = useState(loginInitialState);

  const handleRegisterForm = (event) => {
      const { name, value } = event.target;
      setRegisterForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRegister = async (event) => {
      event.preventDefault();
      try {
          registerUser(dispatch, registerForm);
          setRegisterForm(loginInitialState);
          registerForm.account_type === "User" ? navigate("/profile") : navigate("/");
      } catch (error) {
        Swal.fire({
          title: 'Pruebe de nuevo',
          text: 'Hemos tenido alguna interferencia con el envio del mensaje',
          icon: 'error',
          confirmButtonText: 'Inténtalo de nuevo'
        })
          console.log(error);
      }
  };
 function handleBlur() {
    const hasError = !passwordRegexp.test(passwordField.value);
    setPasswordField((prevState) => ({ ...prevState, hasError }));
  }
  
  return (
    <div className="login mx-auto my-5">
      <div className="col-11 col-md-6 mx-auto my-2">
        <div className="card">
          <div className="card-body text-center">
            <p> Regístrate</p>
            <form className="col-md-9 mx-auto" onSubmit={handleRegister}>
            <input
                type="text"
                className="form-control my-2"
                placeholder="Nombre"
                value={registerForm.name}
                onChange={handleRegisterForm}
                name="name"
              ></input>
              <input
                type="text"
                className="form-control my-2"
                placeholder="UserName"
                value={registerForm.username}
                onChange={handleRegisterForm}
                name="username"
              ></input>
              <input
                type="email"
                className="form-control my-2"
                placeholder="email"
                value={registerForm.email}
                onChange={handleRegisterForm}
                name="email"
              ></input>
              <input
                type="password"
                className="form-control my-2 "
                placeholder="password"
                name="password"
                value={registerForm.password}
                onChange={handleRegisterForm}
                onBlur={handleBlur} 
                aria-invalid={passwordField.hasError}
                pattern="^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,}$"
              ></input>
             <p className="passwordWarning"
        style={{ visibility: passwordField.hasError ? "visible" : "hidden" }}
      >
        La contraseña debe tener mínimo 8 carácteres, 1 minúscula, 1 mayúscula y un número
      </p>
              <button className="btn btn-dark btn-block">Entrar</button>
              <p className="f-small"> ¿Ya estás registrado?  <span> <Link to={"/users/login"}> Log in</Link> </span> </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
