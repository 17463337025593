import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from './routes/routes'
import Header from '../src/components/Header/Header';
import { AuthProvider } from "../src/context/context";

const App = () => {
	return (
		<>
			<AuthProvider> {/* provee el auth a la aplicación */}
        <Router>
          <Header />
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
           
          </Routes>
        </Router>
      </AuthProvider>
		</>
	)
}

export default App
